$(document).foundation({
    equalizer: {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true
    }
});

$(document).ready(function () {

    // filterNews();

    $(".twitter.variant2").wrap("<div class='blockWrapper'></div>");

    $('.row.blocks .blockWrapper').each(function () {
        var block = $(this).height();
        console.log(block);
        $('.twitter-feed').css('height', block);
    })

    // move text to header
    if ($(".customnew").length > 0) {
        moveBlocksToNavigation();
    }

    // hide workgroup members
    if ($('.sectionMembers').length > 0) {
        $('.sectionMembers').remove();
    }

    $('.showdocuments').on('click', function (e) {
        e.preventDefault();
        $(this).next('ul').toggleClass('open');
    });

    // Set background images
    if ($('.bgimage img').length > 0) {
        processBackgroundImages();
    }

    // Listener for hamburger
    $('#hamburger').click(function () {
        $(this).toggleClass('close');
        $('ul.menu').toggleClass('mobile-open');
        hamburgerMenu();
    });

    $(window).scroll(function () {
        if ($("header.header").length > 0) {
            $("header.header").css("top", "0");
        }
    });

    // new menu
    $("ul.menu span.mobileTrigger").click(function (e) {
        e.preventDefault();
        $(this).next("ul.submenu").slideToggle();
        $(this).toggleClass("rotate");
    });

    $(window).scroll(function () {
        var sticky = $('.menu-wrapper'),
            scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
    });

    // Testimonal slider (testimonial js inside combined)
    if ($('.Slider').length > 0) {
        $('.Slider').each(function () {
            $(this).slick({
                autoplay: true,
                autoplaySpeed: 6000,
                arrows: true,
                dots: true,
                infinite: true,
                centerMode: false,
                draggable: false,
                focusOnSelect: false,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        });
    }

    // sticky nav
    if ($('#mainmenu').length > 0) {
        var num = $("#mainmenu").offset().top;
        $(window).bind('scroll', function () {
            if ($(window).scrollTop() > num) {
                $('#mainmenu').addClass('sticky');
            } else {
                $('#mainmenu').removeClass('sticky');
            }
        });
    }

    // Change header height for fixed menu
    if ($('span.languageBtns').length === 0) {
        $('#mainmenu').attr('class', 'custom');
    }

    // Tabs for search results
    $('ul.tabs li').click(function () {
        var tab_id = $(this).attr('data-tab');

        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    })

    // Pick up results to show within Tabs
    if ($('ul.tabs').length) {
        /*$( "<div class='resultsFoundMessage'>Er zijn meer dan: {{Resultaten}} gevonden:</div>" ).insertBefore( ".veld" );*/
    }
});

var moveBlocksToNavigation = function () {
    $('#headertext1').each(function () {
        var firstBlock = $('#headertext1').html();
        var secondBlock = $('#headertext2').html();
        var thirdBlock = $('#headertext3').html();
        var fouthBlock = $('#headertext4').html();
        var fifthBlock = $('#headertext5').html();

        $(firstBlock).appendTo("#item1");
        $(secondBlock).appendTo("#item2");
        $(thirdBlock).appendTo("#item3");
        $(fouthBlock).appendTo("#item4");
        $(fifthBlock).appendTo("#item5");

    });
}

var processBackgroundImages = function () {
    $('.bgimage').each(function () {
        var src = $(this).find('img').attr('src');
        if (src) {
            $(this).css('background-image', 'url("' + src + '")');
        }
    });
};

var hamburgerMenu = function () {
    var currentWidth = $('.header').width();
    if (currentWidth === 0) {
        $('.header').css('width', '100%');
    } else {
        $('.header').css('width', '0');
    }
};

var state = $('#searchLink').attr('class');
$("#searchLink").click(function () {
    $('#searchboxWrapper').stop();

    if (state === 'closed') {
        $("#searchboxWrapper").animate({ width: 'toggle' }, 200);
        $('#searchLink').attr('class', 'open');
        state = 'open';
        $('input[id*=Blok_Header_Searchform]').focus();
    } else if (state === 'open') {
        var aantal = $('input[id*=Block_Header_Searchform_searchbox]').val().length;
        if (aantal >= 1) {
            $('#searchboxWrapper .submitveld input').click();
        } else {
            $("#searchboxWrapper").animate({ width: 'toggle' }, 200);
            $('#searchLink').attr('class', 'closed');
            state = 'closed';
            return false;
        }
    }
});

function filterNews() {
    // init Isotope
    var $grid = $('#newsGrid').isotope({
        itemSelector: '.element-item',
        layoutMode: 'fitRows'
    });

    // store filter for each group
    var filters = {};

    $('.filters').on('click', '.button', function (event) {
        var $button = $(event.currentTarget);
        // get group key
        var $buttonGroup = $button.parents('.button-group');
        var filterGroup = $buttonGroup.attr('data-filter-group');
        // set filter for group
        filters[filterGroup] = $button.attr('data-filter');
        // combine filters
        var filterValue = concatValues(filters);
        // set filter for Isotope
        $grid.isotope({ filter: filterValue });
    });

    // change is-checked class on buttons
    $('.button-group').each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on('click', 'button', function (event) {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            var $button = $(event.currentTarget);
            $button.addClass('is-checked');
        });
    });

    // flatten object by concatting values
    function concatValues(obj) {
        var value = '';
        for (var prop in obj) {
            value += obj[prop];
        }
        return value;
    }

}

$(document).ajaxStop(function () {
    if ($('#newsGrid').length > 0) {
        filterNews();
    }
    // Normal document folder divided
    $('.treeview .folderTrigger').on('click', function () {
        $(this).find('ul').toggleClass('open');
        console.log('clicked');

        var subItem = $('span.folderSubTrigger');
        $(subItem).click(function () {
            $(this).find('ul').toggleClass('open');
        });
    });

    // workgroup documents folder divided
    $('.treeview .folderTrigger').on('click', function () {
        $(this).next('ul').toggleClass('open');
    });


    if ($('.fullWidth.bgimage .block.formV3').length > 0) {
        $('.fullWidth.bgimage .block.formV3 .medium-4.small-12.columns').remove();
    };

    if ($('ul.menu').length > 0) {
        $("a:contains('Mijn KVNR')").attr("id", "mijn-kvnr");
    }

    //set searchquery searchresults
    var searchQuery = $("#zoekterm").text();
    $("#Searchform_searchbox").val(searchQuery);
    $("#Searchform_searchbox").focus();

    // quick fix
    if ($('span.headerText').text().length === 0) {
        $('span.headerText').remove();
    }

    // count results for tab
    if ($('ul.tabs').length) {
        var resultArray = [];
        var resultsfound = $(".resultsFound").each(function (i) {
            var results = parseInt($(this).find('strong.textNumber').text());
            console.log(results);
            resultArray.push(results);
        });
        $('li.tab-link').each(function (i) {
            if (resultArray[i] === "") {
                $(this).find("p").append(" (1)");
            }
            if (resultArray[i] > 0) {
                $(this).find("p").append(" (" + resultArray[i] + ")");
            } else {
                $(this).find("p").append(" (0)");
            }
        });
    };

    // Get input element
    var filterInput = document.getElementById('searchInput');
    filterInput.addEventListener('keyup', filterNames);

    function filterNames() {
        // Get value of input
        var filterValue = document.getElementById('searchInput').value.toUpperCase();

        // Get names ul
        var ul = document.getElementById('workgroupDocumentsList');
        // Get lis from ul
        var li = ul.querySelectorAll('li.document-info');

        // Loop through collection-item lis
        for (var i = 0; i < li.length; i++) {
            var a = li[i].getElementsByTagName('a')[0];
            // If matched
            if (a.innerHTML.toUpperCase().indexOf(filterValue) > -1) {
                li[i].style.display = '';
                li[i].closest('ul.holder').classList.add('open');
                if (filterValue.length == 0) {
                    var elems = [].slice.call(document.querySelectorAll('.holder'))
                    elems.forEach(function (elem) {
                        elem.classList.remove('open');
                    })
                }
            } else {
                li[i].style.display = 'none';
            }
        }
    }
});



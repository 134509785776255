// Nieuwsfiltering

// function filterNews(paging) {
//
//     // Globals
//     this.paging = 1;
//     this.itemsPerPage = 3;
//     this.currentMonth = "";
//     this.currentPage = "";
//     this.allItemsPerMonth = {};
//     this.itemsToShow = [];
//     this.allItems;
//     this.tagIDs = [];
//     this.tagsInUrl = "";
//     this.filterdMonthActive = 11;
//     this.filterActive = false;
//
//     this.getJson = function () {
//         var jsonData = document.querySelector("#jsondata") || null;
//         var rawData = JSON.parse(jsonData.innerHTML);
//         this.allItems = Object.keys(rawData.items).map(function (e) {
//             return rawData.items[e];
//         });
//     }
//
//     this.getActiveTags = function () {
//         this.filterActive = false;
//         var that = this;
//         this.tagIDs = this.tagsInUrl.length > 0 ? this.tagsInUrl : [];
//         var activeTags = [].slice.call(document.querySelectorAll(".tag input.active")) || null;
//         activeTags.map(function (elem) {
//             if (that.tagIDs.indexOf(elem.getAttribute("id")) < 0) {
//                 that.tagIDs.push(elem.getAttribute("id"));
//             }
//         });
//         // Set tags on links year
//         var tagParam = "";
//         if (this.tagIDs.length > 0) {
//             tagParam = "?tags=";
//             for (var i = 0; i < this.tagIDs.length; i++) {
//                 tagParam += this.tagIDs[i];
//                 if (i !== this.tagIDs.length - 1) {
//                     tagParam += ",";
//                 }
//             }
//         }
//
//         var years = [].slice.call(document.querySelectorAll(".pagination.years.js-years li a"));
//         years.map(function (year) {
//             var href = year.getAttribute("data-ori-href");
//             year.setAttribute("href", href + tagParam);
//         });
//         this.tagsInUrl = "";
//         this.tagIDs.length > 0 ? this.filterActive = true : this.filterActive = false;
//     }
//
//     this.fillListWithItems = function () {
//         var urlsUsed = [];
//         for (var j = 0; j < this.allItems.length; j++) {
//             !this.allItemsPerMonth[this.allItems[j].item.dateMonth] ? this.allItemsPerMonth[this.allItems[j].item.dateMonth] = [] : null;
//             this.allItemsPerMonth[this.allItems[j].item.dateMonth].push(this.allItems[j]);
//
//             if (this.allItems[j].item.metatags) {
//                 for (var i = 0; i < this.allItems[j].item.metatags.length; i++) {
//                     if (this.tagIDs.indexOf(this.allItems[j].item.metatags[i]) > -1) {
//                         //!this.itemsToShow[0] ? this.itemsToShow[0] = [] : null;
//
//                         if (!urlsUsed.indexOf(this.allItems[j].item.url) > -1) {
//                             this.itemsToShow.push(this.allItems[j]);
//                             urlsUsed.push(this.allItems[j].item.url);
//                         }
//                         break;
//                     }
//                 }
//             }
//         }
//     }
//
//     this.buildList = function () {
//         var that = this;
//         var listItems = this.tagIDs.length > 0 ? this.itemsToShow : this.allItemsPerMonth;
//
//         this.tagIDs.length > 0 ? this.filterActive = true : this.filterActive = false;
//         if (this.filterActive === true) {
//
//             that.listHTML(listItems);
//         } else {
//             for (var k = 12; k > 0; k--) {
//                 if (parseInt(this.currentMonth) > -1) {
//                     that.listHTML(listItems[parseInt(this.currentMonth) + 1]);
//                     break;
//                 } else if (listItems[k]) {
//                     this.currentMonth = k - 1;
//                     that.listHTML(listItems[k]);
//                     break;
//                 } else {
//                     that.listHTML("");
//                 }
//             }
//         }
//     }
//
//     this.showMonths = function (c) {
//
//         var that = this;
//         var currentList = this.tagIDs.length > 0 ? this.itemsToShow : this.allItemsPerMonth;
//         var monthHolder = document.querySelector("[data-pagination=months]") || null;
//         var months = ["jan", "feb", "mrt", "apr", "mei", "juni", "juli", "aug", "sep", "okt", "nov", "dec"];
//         if (monthHolder) {
//             monthHolder.innerHTML = "";
//             var list = document.createElement("ul");
//             list.classList.add("pagination", "months");
//
//             if (this.filterActive === false) {
//
//                 for (var i = 0; i < 12; i++) {
//                     var listItem = document.createElement("li");
//                     if (currentList[i + 1]) {
//                         listItem.innerHTML = "<a href='#' data-month='" + i + "'>" + months[i] + "</a>";
//                     } else {
//                         listItem.innerHTML = "<span>" + months[i] + "</span>";
//                     }
//
//                     list.appendChild(listItem);
//                 }
//                 monthHolder.appendChild(list);
//
//                 var monthsShown = [].slice.call(monthHolder.querySelectorAll("li"));
//                 for (var k = 11; k > -1; k--) {
//                     if ((k == that.currentMonth) && (currentList[k + 1])) {
//                         monthsShown[k].classList.add("active");
//                     }
//                     /*if (monthsShown[k].querySelector("a")) {
//                         monthsShown[k].classList.add("active");
//                         k = 0;
//                     }*/
//                 }
//                 this.monthEvents();
//             }
//         }
//
//     }
//
//     this.monthEvents = function () {
//         var that = this;
//         var months = [].slice.call(document.querySelectorAll("ul.pagination.months li a")) || null;
//         for (var i = 0; i < months.length; i++) {
//             months[i].addEventListener("click", function (e) {
//                 e.preventDefault();
//                 that.currentMonth = this.getAttribute("data-month");
//                 for (var j = 0; j < months.length; j++) {
//                     months[j].getAttribute("data-month") === that.currentMonth ? months[j].parentNode.classList.add("active") : months[j].parentNode.classList.remove("active");
//                 }
//                 document.querySelector(".pagination-buttons.with-filter").innerHTML = "";
//                 that.restartFilter(true);
//             });
//         }
//     }
//
//     this.restartFilter = function (keepBtns) {
//         this.filterActive = false;
//         document.querySelector(".pagination-buttons.with-filter").innerHTML = "";
//         this.paging = 1;
//         this.itemsToShow = [];
//         this.allItemsPerMonth = {};
//         this.getActiveTags();
//         this.fillListWithItems();
//         this.showMonths(this.currentMonth);
//         this.buildList();
//         this.pageBtnsEvents();
//     }
//
//     this.listHTML = function (listItems) {
//
//         var that = this;
//         var list = document.querySelector(".listing .overview");
//         var pagination = document.querySelector(".pagination-buttons.with-filter");
//         var btns = document.querySelectorAll(".pagination-buttons.with-filter .pagination a");
//         var page = 1;
//         list.innerHTML = "";
//
//         if (!listItems) {
//             list.innerHTML = "<p>Er zijn geen resultaten gevonden.</p>";
//             if (this.currentMonth > 0) {
//                 this.currentMonth--;
//                 this.restartFilter();
//             }
//         } else {
//             if (btns.length > 0) {
//                 var btns = [].slice.call(document.querySelectorAll(".pagination-buttons.with-filter .pagination a"));
//                 btns.map(function (btn) {
//                     if (btn.getAttribute("href") === "#page-" + that.paging) {
//                         btn.classList.add("active");
//                     } else {
//                         btn.classList.remove("active");
//                     }
//                 });
//             } else {
//                 pagination.innerHTML = "<div class='pagination'><span class='page'><a class='active' href='#page-1'>1</a></span></div>";
//             }
//
//
//             for (var i = 0; i < listItems.length; i++) {
//
//                 listItems.length;
//
//                 var itemElem = document.createElement("li");
//                 var classActive = that.paging === page ? "active" : "";
//                 itemElem.setAttribute("class", "page-" + page + " " + classActive + " small-12 medium-4 large-4 columns left");
//                 if (this.filterActive === false) {
//                     itemElem.setAttribute("data-month", listItems[i].item.date.split("-")[1]);
//                 }
//
//                 itemElem.innerHTML = "<div class='content'>" +
//                     "<figure><div class='date'>" +
//                     "<span class='day'>"+ listItems[i].item.dateDay +"</span><span class='month'>"+ listItems[i].item.Month +"</span></div><img src='" + listItems[i].item.img + "'/></figure>" +
//                     "<strong><a href='" + listItems[i].item.url + "'>" + listItems[i].item.title + "</a></strong>" +
//                     // "<time>" + listItems[i].item.date + "</time>" +
//                     "<p>" + listItems[i].item.intro + "</p>";
//                     // "<a href='" + listItems[i].item.url + "'> Lees meer</a>";
//
//                 list.appendChild(itemElem);
//
//                 if (i === (page * that.itemsPerPage) - 1) {
//                     if (btns.length === 0) {
//                         pagination.innerHTML += "<div class='pagination'><span class='page'><a class='' href='#page-" + (page + 1) + "'>" + (page + 1) + "</a></span></div>";
//                     }
//                     page++;
//                 }
//             }
//
//             // wrap items in rows
//             var totalLists = $(".news.overview.filtered > li");
//             for(var i = 0; i < totalLists.length; i+=3) {
//                 totalLists.slice(i, i+3).wrapAll("<div class='row'></div>");
//             }
//         }
//     }
//
//     this.pageBtnsEvents = function () {
//         var that = this;
//         var pageBtns = [].slice.call(document.querySelectorAll(".pagination-buttons.with-filter .pagination a")) || null;
//         pageBtns.map(function (btn) {
//             btn.addEventListener("click", function (e) {
//                 e.preventDefault();
//                 var targetPage = parseInt(this.getAttribute("href").split("-")[1]);
//                 that.paging = targetPage;
//                 that.buildList();
//                 that.smoothScroll();
//             });
//         });
//     }
//
//     this.smoothScroll = function () {
//         var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
//         var animation;
//         if (currentScroll > 10) {
//             animation = requestAnimationFrame(this.smoothScroll);
//             window.scrollTo(0, currentScroll - (currentScroll / 5));
//         } else {
//             window.scrollTo(0, 0);
//             cancelAnimationFrame(animation);
//         }
//     }.bind(this);
//
//     this.tagEvents = function () {
//         var that = this;
//         var mainTagGroup = [].slice.call(document.querySelectorAll(".theme-tag"));
//         mainTagGroup.map(function (group) {
//             group.querySelector(".filtering").addEventListener("click", function () {
//                 group.classList.toggle("open");
//             });
//         });
//
//         var tags = [].slice.call(document.querySelectorAll(".tag"));
//         tags.map(function (tag) {
//             tag.querySelector("input").addEventListener("change", function () {
//                 if (this.classList.contains("all")) {
//                     var checked = this.checked || this.classList.contains("active");
//                     var allTagsInGroup = [].slice.call(this.parentNode.parentNode.querySelectorAll("input"));
//                     for (var i = 0; i < allTagsInGroup.length; i++) {
//                         if (!allTagsInGroup[i].classList.contains("all")) {
//                             allTagsInGroup[i].classList.add("active");
//                             if (this.checked === true) {
//                                 allTagsInGroup[i].classList.remove("active");
//                                 allTagsInGroup[i].classList.add("active");
//                                 allTagsInGroup[i].checked = "checked";
//                             } else {
//                                 allTagsInGroup[i].classList.remove("active");
//                                 allTagsInGroup[i].checked = "";
//                             }
//                         }
//                     }
//                 } else {
//                     this.parentNode.parentNode.querySelector(".all").classList.remove("active");
//                     this.parentNode.parentNode.querySelector(".all").checked = "";
//                 }
//                 this.classList.toggle("active");
//                 that.restartFilter();
//             });
//         });
//     }
//
//     this.getTagsFromURL = function (name) {
//         var that = this;
//         name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
//         var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
//         var results = regex.exec(location.search);
//         this.tagsInUrl = results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' ')).split(",");
//         if (this.tagsInUrl.length > 0) {
//             var tags = [].slice.call(document.querySelectorAll(".tag input"));
//             tags.map(function (tag) {
//                 if (that.tagsInUrl.indexOf(tag.getAttribute("id")) > -1) {
//                     tag.classList.add("active");
//                     tag.setAttribute("checked", "checked");
//                 }
//             });
//         }
//     }
//
//     this.reset = function () {
//         var that = this;
//         var resetBtns = [].slice.call(document.querySelectorAll(".js-reset"));
//         resetBtns.map(function (resetBtn) {
//             resetBtn.addEventListener("click", function () {
//                 var tags = [].slice.call(document.querySelectorAll(".tag input"));
//                 tags.map(function (tag) {
//                     tag.classList.remove("active");
//                     tag.checked = false;
//                     that.restartFilter();
//                 });
//             });
//         });
//     }
//
//     this.init = function () {
//         this.getJson();
//         this.getTagsFromURL("tags");
//         this.getActiveTags();
//         this.fillListWithItems();
//         this.buildList();
//         this.showMonths(Object.keys(this.allItemsPerMonth).length);
//         this.pageBtnsEvents();
//
//         this.tagEvents();
//         this.reset();
//     }
// };
//
// $(document).ajaxStop(function () {
//     var news = new filterNews(1);
//     news.init();
//
//     var yearactive = false;
//     var years = [].slice.call(document.querySelectorAll(".js-years li a")) || null;
//     years.map(function (year) {
//         year.classList.contains("active") ? yearactive = true : null;
//     });
//     if (yearactive === false) {
//         years[0].classList.add("active");
//     }
// });
//
// function filterNewsByTags() {
//
// }